import { REST_URLS } from 'habitual-analytics/api/resturls';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { defaultSettingPreferences } from 'habitual-analytics/constants/dynamicAppConfigs';
import { defaultOrderPreferences } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import qs from 'qs';

const getUserData = () => {
  const { GET_USER } = REST_URLS();
  const ApiUrl = `${GET_USER}`;

  return wrappedFetch(ApiUrl)
    .then((response) => response.json())
    .then((result) => {
      initDynamicAppConfigs({
        orderPreferences: _.get(result, 'order_preferences', defaultOrderPreferences),
        settingPreferences: _.get(result, 'setting_preferences', defaultSettingPreferences),
      });
      return result;
    });
};

const signOut = () => {
  const { SIGN_OUT } = REST_URLS();
  const ApiUrl = `${SIGN_OUT}`;

  return wrappedFetch(ApiUrl).then((res) => res);
};

const updateUser = (args) => {
  const { UPDATE_USER } = REST_URLS();
  const ApiUrl = `${UPDATE_USER}`;

  const parameters = {
    method: 'PUT',
    body: JSON.stringify(args),
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

const getMobileToken = () => {
  const { broker, brokerClientId, brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { GENERATE_MOBILE_TOKEN } = REST_URLS();

  const queryParams = qs.stringify({
    broker: broker,
    broker_client_id: brokerClientId,
    broker_access_token: brokerClientAccessToken,
  });

  const uri = `${GENERATE_MOBILE_TOKEN}?${queryParams}`;

  return wrappedFetch(uri)
    .then((response) => response.json())
    .then((result) => {
      const { token } = result;
      if (token) {
        initDynamicAppConfigs({ mobileToken: token });
      }
      return result;
    });
};

const checkBrokerUserExistence = (args) => {
  const queryParams = qs.stringify({ ...args });
  const uri = `/user/check_broker_user_existence?${queryParams}`;

  return wrappedFetch(uri)
    .then((response) => response.json());
};

const validateBrokerUser = (args) => {
  const queryParams = qs.stringify({ ...args });
  const uri = `/user/validate_broker_client?${queryParams}`;
  return wrappedFetch(uri)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export { getUserData, signOut, updateUser, getMobileToken, checkBrokerUserExistence, validateBrokerUser };
