const PROFILE_PATH = '/user/profile';

const RestEndPoints = {
  GET_HOLDINGS: '/portfolio/holdings',
  GET_POSITION_BOOK: '/portfolio/positions',
  GET_TRADE_BOOK: '/orders/dealertradebook',
  GET_ORDER_BOOK: '/orders',
  PLACE_ORDER: '/orders',
  MODIFY_ORDER: '/orders',
  PROFILE_PATH: `${PROFILE_PATH}`,
  CANCEL_PLACE_ORDER: '/orders',
};

export default RestEndPoints;

