import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseOrderDetails, parsePlaceOrder, parseModifyOrder } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import RestEndPoints from './RestEndPoints';
import { REST_URLS } from 'habitual-analytics/api/resturls';


const getRequestUri = (method, qs = 'clientID=*****', path = '') => {
  const { brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';
  const baseURI = brokerExtras?.api_base_uri || '';

  return `${baseUrl}?broker=kasat&method=${method}${queryString}${pathString}&baseURI=${baseURI}`;
};

const requestHeaders = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  return {
    'Content-Type': 'application/json',
    Authorization: brokerClientAccessToken,
  };
};

const getBaseUrl = (endPoint, params = '') => {
  const { brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = RestEndPoints?.[endPoint];

  return `${brokerExtras?.api_base_uri}${ApiUrl}?clientID=*****&${params}`;
};

const isValidAccessToken = () => {
  const parameters = {
    headers: requestHeaders(),
  };

  return wrappedFetch(getBaseUrl('GET_ORDER_BOOK'), parameters)
    .then((response) => response.json())
    .then((res) => res['type'] === 'success')
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = () => {
  const parameters = {
    headers: requestHeaders(),
  };

  return wrappedFetch(getBaseUrl('GET_ORDER_BOOK'), parameters)
    .then((response) => response.json())
    .then(async (result) => ({
      orderBookDetails: _.compact(
        _.orderBy(
          await parseOrderDetails(result['result'], 'order'),
          ['time'],
          ['desc']
        )
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const parameters = {
    headers: requestHeaders(),
  };

  return wrappedFetch(getBaseUrl('GET_TRADE_BOOK'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result['result'], 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const parameters = {
    headers: requestHeaders(),
  };

  return wrappedFetch(getBaseUrl('GET_POSITION_BOOK', 'dayOrNet=DayWise'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(
              _.get(result, 'result.positionList'),
              'position'
            ),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const parameters = {
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getBaseUrl('GET_HOLDINGS'),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      const holdingData = _.get(result, 'result.RMSHoldings.Holdings', {});
      const objectToArrayConversion = _.values(holdingData);
      return {
        holdingBookDetails: _.compact(
          await parseOrderDetails(objectToArrayConversion, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);
      const requestBody = JSON.stringify(orderDetails);

      const parameters = {
        method: 'POST',
        headers: requestHeaders(),
        body: requestBody,
      };

      return wrappedFetch(getBaseUrl('PLACE_ORDER'), parameters)
        .then((response) => {
          return response.json();
        })
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ result, type }) =>
      type === 'success' ? result['AppOrderID'] : null
    )
    .filter((value) => value !== null)
    .value();
};

const cancelPlaceOrder = (orderConfig) => {
  const appOrderID = _.get(orderConfig, 'orderNo', '');
  const parameters = {
    method: 'DELETE',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getBaseUrl('CANCEL_PLACE_ORDER', `appOrderID=${appOrderID}`),
    parameters
  ).then((response) => response.json());
};

const modifyPlaceOrder = (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const modifyOrderConfig = parseModifyOrder(placeOrderConfigs[0]);
  const parameters = {
    method: 'PUT',
    headers: requestHeaders(),
    body: JSON.stringify(modifyOrderConfig),
  };

  return wrappedFetch(
    getBaseUrl('MODIFY_ORDER', `ClientID=${brokerClientId}`),
    parameters
  )
    .then((response) => response.json())
    .then((data) => [data?.result])
    .catch((e) => [e]);
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
  modifyPlaceOrder,
};
